









































































































import { CarType } from '@/api/interfaces/cartype'
import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'

export default defineComponent({
  name: 'SelectSearchCarType',
  components: {
    SelectSearch: () => import('../m-select-search.vue'),
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {
        return {}
      },
    },
  },
  data () {
    return {
      dialogCarType: false,
      isSubmiting: false,
      type: '',
    }
  },
  computed: {
    ...mapState('carType', {
      carTypeList: 'list',
    }),
  },
  created () {
    this.load()
  },
  methods: {
    ...mapActions('carType', {
      load: 'load',
      save: 'save',
    }),
    itemText (item: CarType) {
      // use this function instead of arrow function in item-text prop, workaround for https://github.com/vuetifyjs/vuetify/issues/11585
      return item.type
    },
    onInput (uri: string) {
      const carType = (this.carTypeList as Array<CarType>).find(
        x => x['@id'] === uri,
      )
      this.$emit('input', carType)
    },
    getValidationObserver () {
      return this.$refs.observer as InstanceType<typeof ValidationObserver>
    },
    async onSubmit () {
      this.isSubmiting = true
      try {
        const newCarType = await this.save(this.type)
        this.$emit('input', newCarType)
        this.clear()
        this.dialogCarType = false
      } finally {
        this.isSubmiting = false
      }
    },
    clear () {
      this.type = ''
      this.getValidationObserver().reset()
    },
    createType () {
      this.dialogCarType = !this.dialogCarType
    },
  },
})
